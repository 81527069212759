<template>
    <main>
        <HeaderTab :title="$t('menu.setting.simulateur_croisement')" />
        <div id="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="box mt-5">
                            <form @submit.prevent="validForm">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label>{{ $t('monte.etalon') }}<sup>*</sup></label>
											<e-select
												v-model="stallion"
												id="horse_id"
												track-by="horse_id"
												label="horse_nom"
												:placeholder="$t('monte.selectionnez_etalon')"
												:selectedLabel="$t('select.selectedLabel')"
												:options="stallions"
												:searchable="true"
												:allow-empty="false"
												:loading="isLoadingStallion"
												:show-labels="false"
											>
												<template slot="first" slot-scope="{ option }">{{ option.label }}</template>
												<template slot="noOptions">{{ $t('global.list_empty') }}</template>
											</e-select>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label>{{ $t('monte.jument') }}<sup>*</sup></label>
											<e-select
												v-model="mare"
												id="horse_id"
												track-by="horse_id"
												label="horse_nom"
												:placeholder="$t('monte.selectionnez_jument')"
												:selectedLabel="$t('select.selectedLabel')"
												:options="mares"
												:searchable="true"
												:allow-empty="false"
												:loading="isLoadingMare"
												:show-labels="false"
											>
												<template slot="first" slot-scope="{ option }">{{ option.label }}</template>
												<template slot="noOptions">{{ $t('global.list_empty') }}</template>
											</e-select>
                                        </div>
                                    </div>

                                </div>

                                <!-- Message d'erreur si besoin -->
                                <ErrorAlert v-if="message_erreur_code !== ''" :messageI18n="message_erreur_code" />

                                <!-- Bouton de validation du formulaire -->
                                <div class="text-center mt-5">
                                    <button type="submit" v-on:click="checkForm" class="btn btn-primary rounded-pill">
                                        <font-awesome-icon :icon="['far', 'file-pdf']"/> {{ $t('monte.simuler_croisement') }}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</main>
</template>

<script type="text/javascript">
    import ConfigMixin from '@/mixins/Config';
    import HorseMixin from '@/mixins/Horse';
    import Tools from '@/mixins/Tools';

    export default {
        name: "HorseCreation",
        mixins: [ConfigMixin, HorseMixin, Tools],
        data () {
            return {
				mares: [],
				stallions: [],

				mare: null,
				stallion: null,

                required_values: [
					'mare',
					'stallion'
				],
				message_erreur_code: '',
				isLoadingMare: true,
				isLoadingStallion: true
            }
        },
        mounted() {
            this.init_component()
        },
        methods: {
            async init_component() {
				this.mares = await this.getHorseFemelle()
				this.stallions = await this.getHorsesStallion()

				this.isLoadingMare = false
				this.isLoadingStallion = false
            },

            async validForm() {
				if(this.checkForm()) {
					this.generatePedigreePdf(this.mare.horse_id, this.stallion.horse_id)
				}
            },

            checkForm() {
                let el = ''
                let error = false

                for(let i=0; i<this.required_values.length; i++) {
                    el = this.required_values[i]

                    if(this[el] === null || this[el] === undefined) {
                        error = true
                    }
                }

                if(error) {
                    this.message_erreur_code = "formulaire.erreur_champs_non_remplis"
					return false
                }

				return true
            },
        },
        components: {
            HeaderTab: () => import('@/components/HeaderTab'),
            ErrorAlert: () => import('GroomyRoot/components/Alert/ErrorAlert'),
        }
    };
</script>
